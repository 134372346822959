.newclient {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 5px 5px 5px 5px rgb(202, 197, 197);
  border-radius: 5px;
  padding: 40px 0;
  font-size: 15px;
  color: $letter;
  width: 60%;

  label {
    display: flex;
    svg {
      margin-right: 7px;
    }
  }
}

.divInputForm {
  border-bottom: 2px solid rgb(204, 201, 201);
  margin: 15px 0;
  padding: 0 10px;

  input {
    margin: 5px 0;
    border-radius: 5px;
    background-color: transparent;
    border: none;

    &:focus {
      outline: none;
      transform: scale(1.1);
      color: $letter;
    }
  }
}

.create {
  font-size: 15px;
  color: $letter;
  margin: 15px 0;
  text-decoration: none;
  font-weight: bold;

  &:hover {
    transform: scale(1.1);
  }

}

.cancel {
    font-size: 15px;
    color: $letter;
    margin: 15px 0;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      transform: scale(1.1);
    }
  }
