.home {
    width: 100%;
    
    .search-container {
        width: fit-content;
        margin: auto;
        padding: 2rem 5rem;
    }

    .containerBox {
        margin: 45px 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        align-items: center;
    }

    .modalButtonClient {
        text-align: center;
    }
}