.table {
    // width: 90%;
    // border-collapse: collapse;
    margin: 1rem auto;

    th,
    td {
        border: none;
        padding: 12px;
        text-align: left;
    }

    th {
        font-weight: normal;
        background-color: $letter;
        color: $color-white;
    }

    tbody tr:nth-child(odd) {
        background-color: $color-light;
    }

    tbody tr:hover {
        background-color: $base-transp;
    }
}