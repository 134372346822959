.leftmenu {
    width: 20vw;
    box-shadow: 3px 3px 2px 3px rgb(204, 201, 201);
    color: $letter;
    height: 100vh;

    div{
        text-align: center;
        padding: 15px 0;
        img {
            width: 50%;
        }
    }

    ul{
        margin: 0; 
        padding: 0;

        .homeLi {
            display: flex;
            align-items: center;
            svg {
                margin-right: 7px;
            }
        }


        h3 {
            margin: 25px 0 5px 15px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 7px; 
            }
        }

        a {
            list-style: none;
            padding: 5px 0;
            padding-left: 35px;
            display: flex;
            align-content: center;
            text-decoration: none;
            color:inherit; 
            &:hover {
                background-color: rgb(204, 201, 201);
                transform: scale(1.03);
                cursor: pointer;
            }
        }

        .logout_button {
            list-style: none;
            padding: 5px 0;
            padding-left: 35px;
            display: flex;
            align-content: center;
            text-decoration: none;
            color:inherit; 

            &:hover {
                background-color: rgb(204, 201, 201);
                transform: scale(1.03);
                cursor: pointer;
            }
        }

    }

}