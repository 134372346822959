@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300&family=Mulish&family=Roboto+Mono:wght@300&display=swap');

* {
    margin: 0;
    padding: 0;
    font-family: 'Mulish', sans-serif;
    box-sizing: border-box;
}


$color-black: #000000;
$color-white: #ffffff;
$color-dark: #151515;
$color-light: #f2f2f2;
$base: #a7f1cb;
$base-transp: #a7f1cb81;
$secondbase: #f39094;
$letter: #8F1D21;
$letter_light: #b11b20;
$gray_light: #726464;

$font-xs: 0.4em;
$font-sm: 0.6em;
$font-md: 0.8em;
$font-lg: 1em;
$font-xl: 1.2em;

$border-radius-sm: 0.3rem;
$border-radius-md: 0.5rem;
$border-radius-lg: 0.7rem;