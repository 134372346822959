.detailClient {
    width: 100%;


    .headerDetailClient {
        display: flex;
        justify-content: space-between;
        box-shadow: 3px 3px 3px 2px rgb(204, 201, 201);
        padding: 0 2px;

        .cardClientDetail {
            padding: 40px 60px;
            text-align: left;
            word-wrap: break-word;
            overflow-wrap: break-word;
            @media screen and (max-width: 767px) {
                padding: 10px;
                font-size: small;
            }
        }

        .containerButtonDetail {
            width: 60%;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 5px;
           

            @media screen and (max-width: 767px) {
                width: 95%;
                padding: 5px;
                flex-direction: column;
                align-items: stretch;
            }

        }

        @media screen and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
        }
    }

    .containerBoxClient {
        padding: 35px 50px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;

        @media screen and (max-width: 767px) {
            padding: 15px;
        }
    }
}