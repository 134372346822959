.boxHome {
    color: $gray_light;
    text-align: center;
    border-radius: 5px;
    margin: 5px 15px;
    padding: 10px 10px;
    box-shadow: 3px 3px 3px 2px rgb(204, 201, 201);
    transition: all 500ms ease;
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    text-decoration: none;
    flex: 1;
    
    :visited {
        color: inherit;
        text-decoration: line-through;
    }

    &:hover {
        transform: scale(1.05);
        cursor: pointer;
        background-color: $base-transp;
        color: black;
        
    }

    hr {
        margin: 15px 0;
    }

    p {
        b {
            color: $letter;
            font-size: xx-large;
        }
    }

}
.greenBox {
    background-color: rgb(166, 230, 166);
}

.redBox {
    background-color: rgb(226, 157, 157);

}