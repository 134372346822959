.button {
    background-color: $base;
    border: 0;
    border-radius: $border-radius-sm;
    -webkit-border-radius: $border-radius-sm;
    -moz-border-radius: $border-radius-sm;
    -ms-border-radius: $border-radius-sm;
    -o-border-radius: $border-radius-sm;
    color: $color-dark;
    cursor: pointer;
    font-size: $font-md;
    margin: 1rem 0;
    padding: 0.5rem 2rem;
    text-transform: capitalize;
}