.formSearch {
    display: flex;
    flex-direction: row;
    align-items: center;

    .buttonSearch {
        margin-left: 20px;
        &:hover {
            transform: scale(1.1);
            cursor: pointer;
        }
    }


}