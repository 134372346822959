.login_form{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%; 
    
    .form {

        @media screen and (max-width: 767px) {
            width: 90%;
        }
        
    }
}