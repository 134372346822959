.upMenu {
    display: flex;
    justify-content: space-around;
    align-items: center;
    box-shadow: 3px 3px 2px 3px rgb(204, 201, 201);
    margin-bottom: 15px;

    .logoChavalin {
        width: 130px;
    }

}