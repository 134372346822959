.allClients {
    width: 100%;

    .containerTable {
        text-align: center;
        padding: 45px;

        a {
            text-decoration: none;
        }

        .smallScreen {
            @media screen and (max-width: 767px) {
                display: none;
            }
        }
    }
}