.neworder {
    display: flex;
   flex-direction: column;
   box-shadow: 5px 5px 5px 5px rgb(202, 197, 197);
   border-radius: 5px;
   padding: 40px 40px;
   font-size: 15px;
   color: $letter;
   width: 40%;

  .icon {
    display: flex;
    justify-content: center;


    p {
      font-size: 15px;
      font-weight: bold;

    }

  }
   
  .order {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 15px 0;
  }
   
  label {
    padding-right: 5px;
  }

  input {
    padding: 0 15px;
    background-color: transparent;
    border: none;
    border-bottom: 2px solid rgb(204, 201, 201);
    
    &:focus {
        outline: none;
        transform: scale(1.1);
    }
  }   
}

Button {
    color: $letter;
    text-decoration: none;
    font-weight: bold;
    
    &:hover {
      transform: scale(1.1);
    }
  
  }
  
  .cancel {
      font-size: 15px;
      color: $letter;
      margin: 5px 0;
      text-decoration: none;
      text-align: center;
  
      &:hover {
        transform: scale(1.1);
      }
    }

