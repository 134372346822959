.app {
    .search_roots{ 
        width: 100%;
        text-align: center;

        .search-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
        }
    }
}