.input-text {
    background-color: $color-white;
    border: 1px solid $base;
    border-radius: $border-radius-sm;
    -webkit-border-radius: $border-radius-sm;
    -moz-border-radius: $border-radius-sm;
    -ms-border-radius: $border-radius-sm;
    -o-border-radius: $border-radius-sm;
    color: $color-dark;
    font-size: $font-lg;
    margin: 1rem 0;
    padding: 0.5rem 1rem;
    text-transform: capitalize;
    display: block;
    width: 300px;

    &:focus {
        outline-color: $base-transp;
    }
}